import { EyeOutlined } from "@ant-design/icons";
import { Column, Line, Pie } from "@ant-design/plots";
import { Icon } from "@iconify/react";
import { Button, Card, Col, Flex, List, Table, Typography } from "antd";
import { useInventoryReportsServiceGetDashboardOverview } from "api/queries";
import { useEffect } from "react";
import { OverviewTopCards } from "./OverviewTopCards";
import OverviewSalesVsPurchases from "./OverviewSalesVsPurchases";

export const DashboardOverView = ({
  range,
}: {
  range: "TODAY" | "THIS_WEEK" | "THIS_MONTH" | "THIS_YEAR";
}) => {
  const { data, isLoading, refetch } =
    useInventoryReportsServiceGetDashboardOverview({
      range,
    });

  useEffect(() => {
    refetch();
  }, [range]);

  return (
    <>
      <OverviewTopCards data={data} isLoading={isLoading} />

      <OverviewSalesVsPurchases purchaseVsSales={data?.purchaseVsSales} isLoading={isLoading} range={range} />

      {/* <Col xs={16}>
        <Card>
          <Typography.Title>Purchase vs. Sale</Typography.Title>
          <Line
            data={[
              {
                month: "may",
                category: "Sale",
                value: 1200,
              },
              {
                month: "june",
                category: "Sale",
                value: 1500,
              },
              {
                month: "july",
                category: "Sale",
                value: 1800,
              },
              {
                month: "august",
                category: "Sale",
                value: 2000,
              },
              {
                month: "september",
                category: "Sale",
                value: 2500,
              },
              {
                month: "may",
                category: "Purchase",
                value: 2000,
              },
              {
                month: "june",
                category: "Purchase",
                value: 300,
              },
              {
                month: "july",
                category: "Purchase",
                value: 2150,
              },
              {
                month: "august",
                category: "Purchase",
                value: 3200,
              },
              {
                month: "september",
                category: "Purchase",
                value: 1200,
              },
            ]}
            yField="value"
            sizeField="value"
            shapeField="smooth"
            legend={{ size: false }}
            colorField="category"
            xField={"month"}
            height={400}
          />
        </Card>
      </Col> */}

      <Col xs={8}>
        <Card>
          <Typography.Title>Upcoming payments</Typography.Title>
          <List
            style={{ height: 400, maxHeight: 400, overflowY: "auto" }}
            className="demo-loadmore-list"
            itemLayout="horizontal"
            dataSource={[1, 2, 3]}
            renderItem={(item) => (
              <List.Item
                actions={[<Button icon={<EyeOutlined />}>View</Button>]}
              >
                <List.Item.Meta
                  title={`INV-0${item}`}
                  description="Due date: Sep 15, 2024"
                />
              </List.Item>
            )}
          />
        </Card>
      </Col>

      <Col xs={10}>
        <Card>
          <Flex justify="space-between" align="center">
            <Typography.Title>Expenses</Typography.Title>
            <Button type="link">View report</Button>
          </Flex>

          <Flex justify="center" align="center">
            <Pie
              height={300}
              data={[
                { type: "Electricity", value: 27 },
                { type: "Utilities", value: 25 },
                { type: "Fuel", value: 18 },
                { type: "Rent", value: 150 },
                { type: "Transport", value: 10 },
              ]}
              angleField="value"
              colorField="type"
              paddingRight={80}
              innerRadius={0.6}
              label={{
                text: "value",
                style: {
                  fontWeight: "bold",
                },
              }}
              legend={{
                color: {
                  title: false,
                  position: "right",
                  rowPadding: 5,
                },
              }}
            />
          </Flex>
        </Card>
      </Col>

      <Col xs={14}>
        <Card>
          <Flex justify="space-between" align="center">
            <Typography.Title>Cashflow</Typography.Title>
            <Button type="link">View report</Button>
          </Flex>
          <Column
            data={[
              {
                label: "May",
                value: 1200,
                type: "Inflow",
              },
              {
                label: "June",
                value: 1500,
                type: "Inflow",
              },
              {
                label: "July",
                value: 1800,
                type: "Inflow",
              },
              {
                label: "August",
                value: 2000,
                type: "Inflow",
              },
              {
                label: "September",
                value: 2500,
                type: "Inflow",
              },
              {
                label: "May",
                value: 3000,
                type: "Outflow",
              },
              {
                label: "June",
                value: 400,
                type: "Outflow",
              },
              {
                label: "July",
                value: 2150,
                type: "Outflow",
              },
              {
                label: "August",
                value: 3200,
                type: "Outflow",
              },
              {
                label: "September",
                value: 1200,
                type: "Outflow",
              },
            ]}
            xField="label"
            yField="value"
            stack={true}
            colorField="type"
            height={300}
            label={{
              text: "value",
              textBaseline: "bottom",
              position: "inside",
            }}
          />
        </Card>
      </Col>

      <Col xs={12}>
        <Card>
          <Flex justify="space-between" align="center">
            <Typography.Title>Low quantity products</Typography.Title>
            <Button type="link">View report</Button>
          </Flex>
          <Table
            key={"low-quantity-products"}
            rowKey={(item) => item.id}
            scroll={{ x: "max-content", y: 400 }}
            columns={[
              //id, name, category, quantity, view action
              { title: "ID", dataIndex: "id", width: 100 },
              { title: "Name", dataIndex: "name", width: 150 },
              { title: "Category", dataIndex: "category", width: 150 },
              { title: "Quantity", dataIndex: "quantity", width: 100 },
              {
                title: "Action",
                dataIndex: "action",
                render: () => <Button type="link">View</Button>,
              },
            ]}
            dataSource={[
              //dummy data
              {
                id: 1,
                name: "Product 1",
                category: "Category 1",
                quantity: 10,
              },
              { id: 2, name: "Product 2", category: "Category 2", quantity: 5 },
              { id: 3, name: "Product 3", category: "Category 3", quantity: 3 },
              { id: 4, name: "Product 4", category: "Category 4", quantity: 2 },
              { id: 5, name: "Product 5", category: "Category 5", quantity: 1 },
              { id: 6, name: "Product 6", category: "Category 6", quantity: 0 },
              { id: 7, name: "Product 7", category: "Category 7", quantity: 0 },
            ]}
          />
        </Card>
      </Col>

      <Col xs={12}>
        <Card>
          <Flex justify="space-between" align="center">
            <Typography.Title>No movement products</Typography.Title>
            <Button type="link">View report</Button>
          </Flex>
          <Table
            key={"no-movement-products"}
            rowKey={(item) => item.id}
            scroll={{ x: "max-content", y: 400 }}
            columns={[
              //id, name, category, quantity, view action
              { title: "ID", dataIndex: "id", width: 100 },
              { title: "Name", dataIndex: "name", width: 150 },
              {
                title: "Last movement on",
                dataIndex: "lastMovement",
                width: 200,
              },
              { title: "Quantity", dataIndex: "quantity", width: 100 },
              {
                title: "Action",
                dataIndex: "action",
                render: () => <Button type="link">View</Button>,
                width: "auto",
              },
            ]}
            dataSource={[
              //dummy data
              {
                id: 1,
                name: "Product 1",
                lastMovement: "Jan 12",
                quantity: 10,
              },
              { id: 2, name: "Product 2", lastMovement: "Feb 12", quantity: 5 },
              { id: 3, name: "Product 3", lastMovement: "Jan 10", quantity: 3 },
              { id: 4, name: "Product 4", lastMovement: "Jan 4", quantity: 2 },
              { id: 5, name: "Product 5", lastMovement: "Jan 5", quantity: 1 },
              { id: 6, name: "Product 6", lastMovement: "Mar 6", quantity: 0 },
              { id: 7, name: "Product 7", lastMovement: "May 7", quantity: 0 },
            ]}
          />
        </Card>
      </Col>
    </>
  );
};
