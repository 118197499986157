import { PlusOutlined } from "@ant-design/icons";
import { useQueryClient } from "@tanstack/react-query";
import {
  Col,
  Form,
  Input,
  message,
  Modal,
  notification,
  Row,
  Select,
} from "antd";
import {
  useAccountingServiceCreateAccount,
  useAccountingServiceGetAccountsKey,
} from "api/queries";
import { useEffect } from "react";
import { AccountSubGroup } from "utils/enums";

export interface ICreateAccountModalProps {
  isOpen: boolean;
  onClose: () => void;
}

const CreateAccountModal = ({ isOpen, onClose }: ICreateAccountModalProps) => {
  const [formRef] = Form.useForm();
  const queryClient = useQueryClient();

  const { mutate, isPending } = useAccountingServiceCreateAccount({
    onSuccess: () => {
      message.success("Account created successfully");
      queryClient.invalidateQueries({
        queryKey: [useAccountingServiceGetAccountsKey],
      });
      onClose();
    },
    onError: (error: any) => {
      notification.error({
        description:
          error?.body?.message ||
          error?.response?.data?.message ||
          error?.message,
        type: "error",
        message: "Failed to create account",
      });
    },
  });

  const handleSubmit = (requestBody: any) => {
    mutate({
      requestBody,
    });
  };

  useEffect(() => {
    formRef.resetFields();
  }, [isOpen]);

  return (
    <Modal
      open={isOpen}
      onCancel={onClose}
      okText="Create"
      okButtonProps={{
        icon: <PlusOutlined />,
        loading: isPending,
      }}
      cancelButtonProps={{ disabled: isPending }}
      onOk={formRef.submit}
    >
      <Row>
        <h1>Create Account</h1>
      </Row>
      <Row>
        <Col xs={24}>
          <Form form={formRef} onFinish={handleSubmit} layout="vertical">
            <Row gutter={16}>
              <Col xs={24}>
                <Form.Item
                  hasFeedback
                  label="Account Name"
                  name="accountName"
                  rules={[
                    {
                      required: true,
                      message: "Account name is required",
                    },
                  ]}
                >
                  <Input name="accountName" placeholder="Account name" />
                </Form.Item>
              </Col>

              <Col xs={24}>
                <Form.Item
                  hasFeedback
                  label="Account Type"
                  name="accountSubgroup"
                  rules={[
                    {
                      required: true,
                      message: "Account type is required",
                    },
                  ]}
                >
                  <Select
                    showSearch
                    options={Object.keys(AccountSubGroup).map((key) => ({
                      value:
                        AccountSubGroup[key as keyof typeof AccountSubGroup],
                      label:
                        AccountSubGroup[key as keyof typeof AccountSubGroup],
                      key: key,
                    }))}
                  />
                </Form.Item>
              </Col>

              <Col xs={24}>
                <Form.Item
                  hasFeedback
                  label="Account Number"
                  name="accountNumber"
                >
                  <Input name="accountNumber" placeholder="Account number" />
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Col>
      </Row>
    </Modal>
  );
};

export default CreateAccountModal;
