export enum TransactionType {
  PURCHASE = "PURCHASE",
  SALE = "SALE",
  JOURNAL_ENTRY = "JOURNAL_ENTRY",
  PAYMENT = "PAYMENT",
  RECEIPT = "RECEIPT",
  TAX = "TAX",
  TRANSFER = "TRANSFER",
  REFUND = "REFUND",
  ADJUSTMENT = "ADJUSTMENT",
  CREDIT_NOTE = "CREDIT_NOTE",
  DEBIT_NOTE = "DEBIT_NOTE",
  EXPENSE = "EXPENSE",
  OPENING_BALANCE = "OPENING_BALANCE",
  BILL = "BILL",
}

export enum AccountType {
  ASSET = "ASSET",
  LIABILITY = "LIABILITY",
  EQUITY = "EQUITY",
  REVENUE = "REVENUE",
  EXPENSE = "EXPENSE",
  CONTRA_ASSET = "CONTRA_ASSET",
  CONTRA_LIABILITY = "CONTRA_LIABILITY",
  CONTRA_EQUITY = "CONTRA_EQUITY",
  CONTRA_REVENUE = "CONTRA_REVENUE",
  CONTRA_EXPENSE = "CONTRA_EXPENSE",
  STATISTICAL = "STATISTICAL",
}

export enum AccountSubType {
  // Asset subtypes
  CURRENT_ASSET = "CURRENT_ASSET",
  FIXED_ASSET = "FIXED_ASSET",
  INTANGIBLE_ASSET = "INTANGIBLE_ASSET",
  LONG_TERM_INVESTMENT = "LONG_TERM_INVESTMENT",
  OTHER_ASSET = "OTHER_ASSET",

  // Liability subtypes
  CURRENT_LIABILITY = "CURRENT_LIABILITY",
  LONG_TERM_LIABILITY = "LONG_TERM_LIABILITY",
  CONTINGENT_LIABILITY = "CONTINGENT_LIABILITY",

  // Equity subtypes
  OWNER_EQUITY = "OWNER_EQUITY",
  RETAINED_EARNINGS = "RETAINED_EARNINGS",
  ACCUMULATED_OTHER_COMPREHENSIVE_INCOME = "ACCUMULATED_OTHER_COMPREHENSIVE_INCOME",

  // Revenue subtypes
  OPERATING_REVENUE = "OPERATING_REVENUE",
  NON_OPERATING_REVENUE = "NON_OPERATING_REVENUE",

  // Expense subtypes
  OPERATING_EXPENSE = "OPERATING_EXPENSE",
  NON_OPERATING_EXPENSE = "NON_OPERATING_EXPENSE",

  // New subtypes
  ROUNDING_ADJUSTMENT = "ROUNDING_ADJUSTMENT",
  CURRENCY_EXCHANGE = "CURRENCY_EXCHANGE",
  OWNERS_EQUITY = "OWNERS_EQUITY",
  OTHER_EXPENSE = "OTHER_EXPENSE",

  // New subtypes
  CONTRA_REVENUE = "CONTRA_REVENUE",
  CONTRA_ASSET = "CONTRA_ASSET",
  CONTRA_LIABILITY = "CONTRA_LIABILITY",
  CONTRA_EQUITY = "CONTRA_EQUITY",
  CONTRA_EXPENSE = "CONTRA_EXPENSE",

  STATISTICAL = "STATISTICAL",
}

export enum AccountGroup {
  // Asset groups
  CASH_AND_EQUIVALENTS = "CASH_AND_EQUIVALENTS",
  ACCOUNTS_RECEIVABLE = "ACCOUNTS_RECEIVABLE",
  INVENTORY = "INVENTORY",
  PREPAID_EXPENSES = "PREPAID_EXPENSES",
  PROPERTY_PLANT_EQUIPMENT = "PROPERTY_PLANT_EQUIPMENT",
  INTANGIBLES = "INTANGIBLES",
  INVESTMENTS = "INVESTMENTS",
  OTHER_ASSETS = "OTHER_ASSETS",

  // Liability groups
  ACCOUNTS_PAYABLE = "ACCOUNTS_PAYABLE",
  SHORT_TERM_DEBT = "SHORT_TERM_DEBT",
  CREDIT_CARDS = "CREDIT_CARDS",
  ACCRUED_LIABILITIES = "ACCRUED_LIABILITIES",
  LONG_TERM_DEBT = "LONG_TERM_DEBT",
  DEFERRED_REVENUE = "DEFERRED_REVENUE",
  OTHER_LIABILITIES = "OTHER_LIABILITIES",

  // Equity groups
  COMMON_STOCK = "COMMON_STOCK",
  PREFERRED_STOCK = "PREFERRED_STOCK",
  ADDITIONAL_PAID_IN_CAPITAL = "ADDITIONAL_PAID_IN_CAPITAL",
  TREASURY_STOCK = "TREASURY_STOCK",
  RETAINED_EARNINGS = "RETAINED_EARNINGS",
  OTHER_COMPREHENSIVE_INCOME = "OTHER_COMPREHENSIVE_INCOME",

  // Revenue groups
  SALES_REVENUE = "SALES_REVENUE",
  SERVICE_REVENUE = "SERVICE_REVENUE",
  INTEREST_INCOME = "INTEREST_INCOME",
  DIVIDEND_INCOME = "DIVIDEND_INCOME",
  OTHER_INCOME = "OTHER_INCOME",

  // Expense groups
  COST_OF_GOODS_SOLD = "COST_OF_GOODS_SOLD",
  SELLING_EXPENSES = "SELLING_EXPENSES",
  GENERAL_ADMIN_EXPENSES = "GENERAL_ADMIN_EXPENSES",
  DEPRECIATION_AMORTIZATION = "DEPRECIATION_AMORTIZATION",
  INTEREST_EXPENSE = "INTEREST_EXPENSE",
  TAX_EXPENSE = "TAX_EXPENSE",
  OTHER_EXPENSES = "OTHER_EXPENSES",

  // New groups
  ROUNDING_DIFFERENCES = "ROUNDING_DIFFERENCES",
  CURRENCY_EXCHANGE_DIFFERENCES = "CURRENCY_EXCHANGE_DIFFERENCES",

  ACCUMULATED_DEPRECIATION = "ACCUMULATED_DEPRECIATION",
  PAYROLL_LIABILITIES = "PAYROLL_LIABILITIES",
  TAX_LIABILITIES = "TAX_LIABILITIES",
  OWNERS_EQUITY = "OWNERS_EQUITY",
  OPERATING_EXPENSES = "OPERATING_EXPENSES",
  DEFERRED_LIABILITIES = "DEFERRED_LIABILITIES",
}

export enum AccountSubGroup {
  // Asset subgroups
  CASH = "CASH",
  BANK_ACCOUNTS = "BANK_ACCOUNTS",
  PETTY_CASH = "PETTY_CASH",
  MARKETABLE_SECURITIES = "MARKETABLE_SECURITIES",
  TRADE_RECEIVABLES = "TRADE_RECEIVABLES",
  ALLOWANCE_FOR_DOUBTFUL_ACCOUNTS = "ALLOWANCE_FOR_DOUBTFUL_ACCOUNTS",
  RAW_MATERIALS = "RAW_MATERIALS",
  WORK_IN_PROGRESS = "WORK_IN_PROGRESS",
  FINISHED_GOODS = "FINISHED_GOODS",
  LAND = "LAND",
  BUILDINGS = "BUILDINGS",
  EQUIPMENT = "EQUIPMENT",
  VEHICLES = "VEHICLES",
  ACCUMULATED_DEPRECIATION = "ACCUMULATED_DEPRECIATION",
  PATENTS = "PATENTS",
  TRADEMARKS = "TRADEMARKS",
  GOODWILL = "GOODWILL",
  LONG_TERM_INVESTMENTS = "LONG_TERM_INVESTMENTS",

  // Liability subgroups
  TRADE_PAYABLES = "TRADE_PAYABLES",
  NOTES_PAYABLE = "NOTES_PAYABLE",
  CREDIT_CARD_PAYABLES = "CREDIT_CARD_PAYABLES",
  CURRENT_PORTION_LONG_TERM_DEBT = "CURRENT_PORTION_LONG_TERM_DEBT",
  WAGES_PAYABLE = "WAGES_PAYABLE",
  TAXES_PAYABLE = "TAXES_PAYABLE",
  INTEREST_PAYABLE = "INTEREST_PAYABLE",
  UNEARNED_REVENUE = "UNEARNED_REVENUE",
  BONDS_PAYABLE = "BONDS_PAYABLE",
  LEASE_OBLIGATIONS = "LEASE_OBLIGATIONS",
  DEFERRED_TAX_LIABILITIES = "DEFERRED_TAX_LIABILITIES",

  // Equity subgroups
  COMMON_STOCK_ISSUED = "COMMON_STOCK_ISSUED",
  PREFERRED_STOCK_ISSUED = "PREFERRED_STOCK_ISSUED",
  CAPITAL_SURPLUS = "CAPITAL_SURPLUS",
  TREASURY_STOCK = "TREASURY_STOCK",
  RETAINED_EARNINGS_UNAPPROPRIATED = "RETAINED_EARNINGS_UNAPPROPRIATED",
  RETAINED_EARNINGS_APPROPRIATED = "RETAINED_EARNINGS_APPROPRIATED",

  // Revenue subgroups
  PRODUCT_SALES = "PRODUCT_SALES",
  SERVICE_SALES = "SERVICE_SALES",
  CONSULTING_REVENUE = "CONSULTING_REVENUE",
  RENTAL_INCOME = "RENTAL_INCOME",
  INTEREST_INCOME = "INTEREST_INCOME",
  DIVIDEND_INCOME = "DIVIDEND_INCOME",
  GAIN_ON_SALE_OF_ASSETS = "GAIN_ON_SALE_OF_ASSETS",

  // Expense subgroups
  DIRECT_MATERIALS = "DIRECT_MATERIALS",
  DIRECT_LABOR = "DIRECT_LABOR",
  MANUFACTURING_OVERHEAD = "MANUFACTURING_OVERHEAD",
  SALARIES_EXPENSE = "SALARIES_EXPENSE",
  RENT_EXPENSE = "RENT_EXPENSE",
  UTILITIES_EXPENSE = "UTILITIES_EXPENSE",
  ADVERTISING_EXPENSE = "ADVERTISING_EXPENSE",
  OFFICE_SUPPLIES_EXPENSE = "OFFICE_SUPPLIES_EXPENSE",
  DEPRECIATION_EXPENSE = "DEPRECIATION_EXPENSE",
  AMORTIZATION_EXPENSE = "AMORTIZATION_EXPENSE",
  INTEREST_EXPENSE = "INTEREST_EXPENSE",
  INCOME_TAX_EXPENSE = "INCOME_TAX_EXPENSE",
  LOSS_ON_SALE_OF_ASSETS = "LOSS_ON_SALE_OF_ASSETS",

  // New subgroups
  ROUNDING_DIFFERENCE_EXPENSE = "ROUNDING_DIFFERENCE_EXPENSE",
  ROUNDING_DIFFERENCE_INCOME = "ROUNDING_DIFFERENCE_INCOME",
  REALIZED_CURRENCY_GAIN = "REALIZED_CURRENCY_GAIN",
  REALIZED_CURRENCY_LOSS = "REALIZED_CURRENCY_LOSS",
  UNREALIZED_CURRENCY_GAIN = "UNREALIZED_CURRENCY_GAIN",
  UNREALIZED_CURRENCY_LOSS = "UNREALIZED_CURRENCY_LOSS",
  INVENTORY = "INVENTORY",
  ACCUMULATED_DEPRECIATION_EQUIPMENT = "ACCUMULATED_DEPRECIATION_EQUIPMENT",
  ACCRUED_EXPENSES = "ACCRUED_EXPENSES",
  SALES_TAX_PAYABLE = "SALES_TAX_PAYABLE",
  INCOME_TAX_PAYABLE = "INCOME_TAX_PAYABLE",
  PAYROLL_TAX_PAYABLE = "PAYROLL_TAX_PAYABLE",
  BANK_LOANS = "BANK_LOANS",
  OWNERS_CAPITAL = "OWNERS_CAPITAL",
  RETAINED_EARNINGS_ACCOUNT = "RETAINED_EARNINGS_ACCOUNT",
  ONLINE_SALES = "ONLINE_SALES",
  RETAIL_SALES = "RETAIL_SALES",
  WHOLESALE_SALES = "WHOLESALE_SALES",
  GENERAL_ADMINISTRATIVE = "GENERAL_ADMINISTRATIVE",
  BANK_FEES = "BANK_FEES",
  INSURANCE_EXPENSE = "INSURANCE_EXPENSE",
  OFFICE_SUPPLIES = "OFFICE_SUPPLIES",
  REPAIRS_MAINTENANCE = "REPAIRS_MAINTENANCE",
  SHIPPING_EXPENSE = "SHIPPING_EXPENSE",
  TELEPHONE_EXPENSE = "TELEPHONE_EXPENSE",
  TRAVEL_EXPENSE = "TRAVEL_EXPENSE",
  WAGES_EXPENSE = "WAGES_EXPENSE",
  PAYROLL_TAX_EXPENSE = "PAYROLL_TAX_EXPENSE",
  EMPLOYEE_BENEFITS_EXPENSE = "EMPLOYEE_BENEFITS_EXPENSE",
  MERCHANDISE_PURCHASES = "MERCHANDISE_PURCHASES",
  RAW_MATERIAL_PURCHASES = "RAW_MATERIAL_PURCHASES",
  FOREIGN_EXCHANGE_GAIN = "FOREIGN_EXCHANGE_GAIN",
  MISCELLANEOUS_EXPENSE = "MISCELLANEOUS_EXPENSE",
  OTHER_ASSET = "OTHER_ASSET",
  OTHER_EXPENSE = "OTHER_EXPENSE",
  OTHER_INCOME = "OTHER_INCOME",
  OTHER_LIABILITY = "OTHER_LIABILITY",
  OTHER_REVENUE = "OTHER_REVENUE",
  OTHER_EQUITY = "OTHER_EQUITY",
  PREPAID_EXPENSES = "PREPAID_EXPENSES",
  DISCOUNT_EXPENSE = "DISCOUNT_EXPENSE",

  DISCOUNTS_GIVEN = "DISCOUNTS_GIVEN",

  CUSTOMER_DEPOSITS = "CUSTOMER_DEPOSITS",

  VARIANCE_ADJUSTMENTS = "VARIANCE_ADJUSTMENTS",

  GST_PAYABLE = "GST_PAYABLE",

  VAT_PAYABLE = "VAT_PAYABLE",
}

export enum LocationType {
  WAREHOUSE = "WAREHOUSE",
  STORE = "STORE",
  OFFICE = "OFFICE",
  FACTORY = "FACTORY",
  OTHER = "OTHER",
}

export enum WeightUnit {
  KILOGRAMS = "KILOGRAMS",
  POUNDS = "POUNDS",
  OUNCES = "OUNCES",
  GRAMS = "GRAMS",
  MILLIGRAMS = "MILLIGRAMS",
  MILLILITERS = "MILLILITERS",
}

export enum MeasurementUnit {
  METER = "METER",
  INCH = "INCH",
  FEET = "FEET",
  CUBIC_METER = "CUBIC_METER",
  CUBIC_FEET = "CUBIC_FEET",
  CUBIC_INCH = "CUBIC_INCH",
  CUBIC_MILLIMETERS = "CUBIC_MILLIMETERS",
  CUBIC_CENTIMETERS = "CUBIC_CENTIMETERS",
  LITERS = "LITERS",
  MILLILITERS = "MILLILITERS",
  MILLIMETERS = "MILLIMETERS",
  CENTIMETERS = "CENTIMETERS",
  METERS = "METERS",
  INCHES = "INCHES",
  YARDS = "YARDS",
  MILES = "MILES",
}

export enum PurchaseOrderStatus {
  DRAFT = "DRAFT",
  SENT = "SENT",
  APPROVED = "APPROVED",
  ON_ROUTE = "ON_ROUTE",
  PARTIALLY_RECEIVED = "PARTIALLY_RECEIVED",
  PARTIALLY_PAID = "PARTIALLY_PAID",
  FULL_PAID = "FULL_PAID",
  FULLY_RECEIVED = "FULLY_RECEIVED",
  CANCELLED = "CANCELLED",
}

export enum PurchaseOrderDeliveryStatus {
  PENDING = "PENDING",
  ON_ROUTE = "ON_ROUTE",
  DELIVERED = "DELIVERED",
  CANCELLED = "CANCELLED",
}

export enum BankAccountType {
  CURRENT = "CURRENT",
  CHECKING = "CHECKING",
  SAVINGS = "SAVINGS",
  LOAN = "LOAN",
  OTHER = "OTHER",
}

export enum BankAccountHolderType {
  INDIVIDUAL = "INDIVIDUAL",
  COMPANY = "COMPANY",
  OTHER = "OTHER",
}

export enum BankAccountHolderIdentificationType {
  IBAN = "IBAN",
  SWIFT = "SWIFT",
  OTHER = "OTHER",
}

export enum BankAccountTransactionType {
  DEBIT = "DEBIT",
  CREDIT = "CREDIT",
  OTHER = "OTHER",
}

export enum SubjectKey {
  ACCOUNT = "ACCOUNT",
  TRANSACTION = "TRANSACTION",
  BANK_ACCOUNT = "BANK_ACCOUNT",
  BANK_ACCOUNT_TRANSACTION = "BANK_ACCOUNT_TRANSACTION",
  EXPENSE_CATEGORY = "EXPENSE_CATEGORY",
  EXPENSE = "EXPENSE",
  TAXES = "TAXES",
  CURRENCY = "CURRENCY",
  CUSTOMER = "CUSTOMER",
  INVOICE = "INVOICE",
  VENDOR = "VENDOR",
  PRODUCTS = "PRODUCTS",
  PURCHASE_ORDER = "PURCHASE_ORDER",
  PRODUCT_CATEGORY = "PRODUCT_CATEGORY",
  BRAND = "BRAND",
  LOCATION = "LOCATION",
  SETTINGS = "SETTINGS",
  USER = "USER",
  SERVICE_CATEGORY = "SERVICE_CATEGORY",
  SERVICE = "SERVICE",
  REPORTS = "REPORTS",
  INVENTORY_TRANSACTION = "INVENTORY_TRANSACTION",
  INVENTORY_STOCK = "INVENTORY_STOCK",
  REPARATIONS = "REPARATIONS",
}

export enum PermissionType {
  READ = "READ",
  CREATE = "CREATE",
  UPDATE = "UPDATE",
  DELETE = "DELETE",
  DOWNLOAD = "DOWNLOAD",
  PRINT = "PRINT",
}
