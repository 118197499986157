import React, { useEffect, useState } from "react";
import {
  Table,
  Typography,
  Row,
  Col,
  Divider,
  Space,
  Statistic,
  Input,
  Button,
} from "antd";
import { TableActions } from "components/common";
import {
  useInventoryServiceDownloadInventoryStockReport,
  useInventoryServiceGetInventoryStock,
  useInventoryServiceGetInventoryStockKey,
} from "api/queries";
import qs from "qs";
import { useQueryClient } from "@tanstack/react-query";
import { ColumnType } from "antd/es/table";
import { motion } from "framer-motion";
import { useNavigate } from "react-router-dom";
import useFilters, {
  FilterTemplate,
  FilterType,
  IFilterItem,
} from "hooks/UseFilter";
import queryString from "query-string";
import { useSelector } from "react-redux";
import { DownloadOutlined } from "@ant-design/icons";

const { Title } = Typography;

const filtersDef: IFilterItem[] = [
  {
    key: "productName",
    label: "Name",
    type: FilterType.Text,
  },
  {
    key: "productCategory",
    label: "Category",
    type: FilterType.template,
    template: FilterTemplate.ProductCategorySelect,
  },
  // {
  //   key: "brand",
  //   label: "Brand",
  //   type: FilterType.template,
  //   template: FilterTemplate.BrandSelect,
  // },
  {
    key: "productSku",
    label: "SKU",
    type: FilterType.Text,
  },
  {
    key: "productBarcode",
    label: "Barcode",
    type: FilterType.Text,
  },
  {
    key: "productWeight",
    label: "Weight",
    type: FilterType.Text,
  },
  {
    key: "productWeightUnit",
    label: "Weight Unit",
    type: FilterType.Text,
  },
  {
    key: "productDimensionUnit",
    label: "Dimension Unit",
    type: FilterType.Text,
  },
  {
    key: "productLength",
    label: "Length",
    type: FilterType.Text,
  },
  {
    key: "productWidth",
    label: "Width",
    type: FilterType.Text,
  },
  {
    key: "productHeight",
    label: "Height",
    type: FilterType.Text,
  },
  {
    key: "productDimension",
    label: "Dimension",
    type: FilterType.Text,
  },
  {
    key: "productPrice",
    label: "Selling Price",
    type: FilterType.Text,
  },
  {
    key: "productCost",
    label: "Buying Price",
    type: FilterType.Text,
  },
  {
    key: "productLowStockAlert",
    label: "Low Stock Alert",
    type: FilterType.Text,
  },
  {
    key: "productIdealStock",
    label: "Ideal Stock",
    type: FilterType.Text,
  },
  {
    key: "productDescription",
    label: "Description",
    type: FilterType.Text,
  },
  {
    key: "productVariants",
    label: "Variabts",
    type: FilterType.template,
    template: FilterTemplate.ProductVariantSelect,
  },
];

const StockListing: React.FC = () => {
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [sortQuery, setSortQuery] = useState("");
  const [searchQuery, setSearchQuery] = useState("");

  const nav = useNavigate();
  const company = useSelector((state: any) => state.company);

  const queryClient = useQueryClient();

  const { filtersQuery, FilterButton, Filters } = useFilters(filtersDef);

  const { mutate: downloadReport, isPending: isDownloadingReport } =
    useInventoryServiceDownloadInventoryStockReport({
      onSuccess: (data) => {
        if (data.downloadUrl) {
          window.open(data.downloadUrl, "_blank");
        }
      },
    });

  const {
    data: stockData,
    isLoading: isStockLoading,
    isRefetching: isRefetchingStock,
  } = useInventoryServiceGetInventoryStock(
    {
      filters: qs.stringify(filtersQuery),
      pagination: queryString.stringify({
        offset: (page - 1) * limit,
        limit,
      }),
      sort: sortQuery,
      searchQuery,
    },
    [useInventoryServiceGetInventoryStockKey]
  );

  useEffect(() => {
    queryClient.invalidateQueries({
      queryKey: [useInventoryServiceGetInventoryStockKey],
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, limit, sortQuery, filtersQuery, searchQuery]);

  const handleTableChange = (pagination: any, filters: any, sorter: any) => {
    if (pagination && pagination.current && pagination.pageSize) {
      setPage(pagination.current || 1);
      setLimit(pagination.pageSize || 10);
    }

    if (sorter) {
      if (sorter.order) {
        const s: any = {};
        s[sorter.field] = sorter.order === "ascend" ? "asc" : "desc";
        setSortQuery(s);
      } else {
        setSortQuery("");
      }
    }
  };

  const columns: ColumnType<any>[] = [
    {
      title: "ID",
      dataIndex: "idProduct",
      key: "idProduct",
      sorter: true,
      width: "auto",
    },
    {
      title: "Name",
      dataIndex: "productName",
      key: "productName",
      sorter: true,
      width: "auto",
    },
    {
      title: "SKU",
      dataIndex: "productSku",
      key: "productSku",
      sorter: true,
      width: "auto",
    },
    {
      title: "Selling Price",
      dataIndex: "productPrice",
      key: "productPrice",
      render: (price: number) =>
        `${company.currencySymbol} ${(price || 0).toFixed(2)}`,
      sorter: true,
      width: "auto",
    },
    {
      title: "Buying Price",
      dataIndex: "productCost",
      key: "productCost",
      render: (cost: number) =>
        `${company.currencySymbol} ${(cost || 0).toFixed(2)}`,
      sorter: true,
      width: "auto",
    },
    {
      title: "Total Stock",
      dataIndex: "totalQuantity",
      key: "totalQuantity",
      render: (totalStock: number) =>
        totalStock || <span style={{ color: "red" }}>Out of stock</span>,
      sorter: true,
      width: "auto",
    },
    {
      title: "Actions",
      key: "actions",
      render: (_v, _r) => (
        <TableActions
          onView={() => nav(`/inventory/products/${_r.idProduct}`)}
        />
      ),
    },
  ];

  const handleDownloadReport = () => {
    downloadReport();
  };

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 1.5, delay: 0 }}
      style={{ position: "relative", height: "100%" }}
    >
      <Row gutter={16} style={{ marginBottom: 20 }}>
        <Col span={8}>
          <Statistic
            title="Total Stock Quantity"
            value={stockData?.totalInventory || 0}
            loading={isStockLoading}
            // formatter={formatter}
          />
        </Col>
        <Col span={8}>
          <Statistic
            title="Total Stock Value"
            value={stockData?.totalCost || 0}
            precision={2}
            loading={isStockLoading}
            // formatter={formatter}
          />
        </Col>

        <Col span={8}>
          <Statistic
            title="Expected Stock Revenue"
            value={stockData?.expectedRevenue || 0}
            precision={2}
            loading={isStockLoading}
          />
        </Col>
      </Row>

      <Row>
        <Col
          xs={24}
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Title level={2} style={{ margin: 0 }}>
            Stock
          </Title>

          <Space>{FilterButton}</Space>
        </Col>

        <Col xs={24}>{Filters}</Col>

        <Col xs={24}>
          <Divider style={{ opacity: 0.5 }} />
        </Col>

        <Col xs={22} style={{ marginBottom: 15 }}>
          <Input.Search
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            placeholder="Search"
            style={{ flex: 1 }}
          />
        </Col>

        <Col xs={2} style={{ textAlign: "right" }}>
          <Button
            type="primary"
            onClick={handleDownloadReport}
            loading={isDownloadingReport}
            icon={<DownloadOutlined />}
          ></Button>
        </Col>
      </Row>
      <Row gutter={12}>
        <Table
          columns={columns}
          dataSource={stockData?.items || []}
          loading={isStockLoading || isRefetchingStock}
          rowKey={(item) => item.idProduct}
          key={"product-list"}
          pagination={{
            current: page,
            pageSize: limit,
            total: stockData?.total,
            onChange: (page) => setPage(page),
            onShowSizeChange: (_, limit) => setLimit(limit),
            pageSizeOptions: [10, 20, 50, 100],
            showSizeChanger: true,
            showTotal: (total, range) =>
              `${range[0]}-${range[1]} of ${total} items`,
          }}
          onChange={handleTableChange}
          scroll={{ x: "max-content" }}
          style={{ width: "100%" }}
        />
      </Row>
    </motion.div>
  );
};

export default StockListing;
