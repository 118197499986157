import React from "react";
import { Card, Col, Typography, Skeleton, Empty } from "antd";
import { Chart, AxisOptions } from "react-charts";

interface OverviewSalesVsPurchasesProps {
  purchaseVsSales?: { purchases: number; sales: number; date: string }[];
  isLoading: boolean;
  range: "TODAY" | "THIS_WEEK" | "THIS_MONTH" | "THIS_YEAR" | "ALL_TIME";
}

const OverviewSalesVsPurchases: React.FC<OverviewSalesVsPurchasesProps> = ({
  purchaseVsSales,
  isLoading,
  range,
}) => {
  // Transform the `purchaseVsSales` data into chart-friendly format
  const chartData = React.useMemo(() => {
    if (!purchaseVsSales) return [];
    const entries = [];

    if (range === "THIS_YEAR") {
      [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ].forEach((month) => {
        entries.push({
          date: month,
          sales: purchaseVsSales
            .filter((entry) => entry.date.includes(month))
            .reduce((acc, entry) => acc + entry.sales, 0),
          purchases: purchaseVsSales
            .filter((entry) => entry.date.includes(month))
            .reduce((acc, entry) => acc + entry.purchases, 0),
        });
      });
    } else {
      entries.push(...purchaseVsSales);
    }

    const a = [
      {
        label: "Sales",
        data: entries.map((entry) => ({
          primary: entry.date, // Parse date for x-axis
          secondary: entry.sales, // Sales value
        })),
      },
      {
        label: "Purchases",
        data: entries.map((entry) => ({
          primary: entry.date, // Parse date for x-axis
          secondary: entry.purchases, // Purchases value
        })),
      },
    ];

    console.log(a);
    return a;
  }, [purchaseVsSales]);

  // Define the primary axis (x-axis) as time-based
  const primaryAxis = React.useMemo<
    AxisOptions<(typeof chartData)[number]["data"][number]>
  >(
    () => ({
      getValue: (datum) => datum.primary, // x-axis value
    }),
    []
  );

  // Define the secondary axis (y-axis) for numeric values
  const secondaryAxes = React.useMemo<
    AxisOptions<(typeof chartData)[number]["data"][number]>[]
  >(
    () => [
      {
        getValue: (datum) => datum.secondary,
        elementType: "line",
      },
    ],
    []
  );

  return (
    <Col xs={24} md={16}>
      <Card>
        <Typography.Title level={4}>Purchase vs. Sale</Typography.Title>
        {isLoading ? (
          <Skeleton active paragraph={{ rows: 8 }} />
        ) : purchaseVsSales && purchaseVsSales.length > 0 ? (
          <div style={{ height: "400px", width: "100%" }}>
            <Chart
              options={{
                data: chartData,
                primaryAxis,
                secondaryAxes,
              }}
            />
          </div>
        ) : (
          <Empty description="No Data Available" />
        )}
      </Card>
    </Col>
  );
};

export default OverviewSalesVsPurchases;
