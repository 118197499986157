import { Icon } from "@iconify/react";
import { Card, Col, Flex, Typography, Skeleton } from "antd";

export const OverviewTopCards = ({
  data,
  isLoading,
}: {
  data: any;
  isLoading: boolean;
}) => {
  return (
    <>
      <Col xs={12} md={6} xl={5}>
        <Card>
          {isLoading ? (
            <Flex justify="center" align="center" style={{ height: "100%" }}>
              <Skeleton
                active
                paragraph={false}
                title={{
                  width: "60%", // Adjust skeleton title width
                }}
              />
            </Flex>
          ) : (
            <Flex justify="space-around" align="center">
              <div style={{ width: "calc(100% - 44px)" }}>
                <Typography.Title level={2} style={{ margin: 0 }} type="success">
                  {data?.totalRevenue}
                  <Typography.Text
                    style={{ fontSize: "0.7rem", marginLeft: 5 }}
                    type={
                      (data?.totalRevenueChangePercentage || 0) >= 0
                        ? "success"
                        : "danger"
                    }
                  >
                    {data?.totalRevenueChangePercentage}%{" "}
                    {(data?.totalRevenueChangePercentage || 0) >= 0 ? (
                      <Icon icon="humbleicons:trending-up" />
                    ) : (
                      <Icon icon="humbleicons:trending-down" />
                    )}
                  </Typography.Text>
                </Typography.Title>
                <Typography.Title level={5} style={{ margin: "0.5rem 0 0 0" }}>
                  Total Revenue
                </Typography.Title>
              </div>
              <Icon icon="healthicons:money-bag-outline" fontSize={40} />
            </Flex>
          )}
        </Card>
      </Col>

      <Col xs={12} md={6} xl={5}>
        <Card>
          {isLoading ? (
            <Flex justify="center" align="center" style={{ height: "100%" }}>
              <Skeleton
                active
                paragraph={false}
                title={{
                  width: "60%",
                }}
              />
            </Flex>
          ) : (
            <Flex justify="space-around" align="center">
              <div style={{ width: "calc(100% - 44px)" }}>
                <Typography.Title level={2} style={{ margin: 0 }} type="success">
                  {data?.totalExpenses}
                  <Typography.Text
                    style={{ fontSize: "0.7rem", marginLeft: 5 }}
                    type={
                      (data?.totalExpensesChangePercentage || 0) >= 0
                        ? "success"
                        : "danger"
                    }
                  >
                    {data?.totalExpensesChangePercentage}%{" "}
                    {(data?.totalExpensesChangePercentage || 0) >= 0 ? (
                      <Icon icon="humbleicons:trending-up" />
                    ) : (
                      <Icon icon="humbleicons:trending-down" />
                    )}
                  </Typography.Text>
                </Typography.Title>
                <Typography.Title level={5} style={{ margin: "0.5rem 0 0 0" }}>
                  Total Expenses
                </Typography.Title>
              </div>
              <Icon icon="solar:bill-list-outline" fontSize={40} />
            </Flex>
          )}
        </Card>
      </Col>

      <Col xs={12} md={6} xl={5}>
        <Card>
          {isLoading ? (
            <Flex justify="center" align="center" style={{ height: "100%" }}>
              <Skeleton
                active
                paragraph={false}
                title={{
                  width: "60%",
                }}
              />
            </Flex>
          ) : (
            <Flex justify="space-around" align="center">
              <div style={{ width: "calc(100% - 44px)" }}>
                <Typography.Title level={2} style={{ margin: 0 }} type="success">
                  {data?.totalInventory}
                  <Typography.Text
                    style={{ fontSize: "0.7rem", marginLeft: 5 }}
                    type={
                      (data?.totalInventoryChangePercentage || 0) >= 0
                        ? "success"
                        : "danger"
                    }
                  >
                    {data?.totalInventoryChangePercentage}%{" "}
                    {(data?.totalInventoryChangePercentage || 0) >= 0 ? (
                      <Icon icon="humbleicons:trending-up" />
                    ) : (
                      <Icon icon="humbleicons:trending-down" />
                    )}
                  </Typography.Text>
                </Typography.Title>
                <Typography.Title level={5} style={{ margin: "0.5rem 0 0 0" }}>
                  Total Inventory
                </Typography.Title>
              </div>
              <Icon icon="hugeicons:delivery-return-01" fontSize={40} />
            </Flex>
          )}
        </Card>
      </Col>

      <Col xs={12} md={6} xl={5}>
        <Card>
          {isLoading ? (
            <Flex justify="center" align="center" style={{ height: "100%" }}>
              <Skeleton
                active
                paragraph={false}
                title={{
                  width: "60%",
                }}
              />
            </Flex>
          ) : (
            <Flex justify="space-around" align="center">
              <div style={{ width: "calc(100% - 44px)" }}>
                <Typography.Title level={2} style={{ margin: 0 }} type="success">
                  {data?.totalSales}{" "}
                  <Typography.Text
                    style={{ fontSize: "0.7rem", marginLeft: 5 }}
                    type={
                      (data?.totalSalesChangePercentage || 0) >= 0
                        ? "success"
                        : "danger"
                    }
                  >
                    {data?.totalSalesChangePercentage}%
                    {(data?.totalSalesChangePercentage || 0) >= 0 ? (
                      <Icon icon="humbleicons:trending-up" />
                    ) : (
                      <Icon icon="humbleicons:trending-down" />
                    )}
                  </Typography.Text>
                </Typography.Title>
                <Typography.Title level={5} style={{ margin: "0.5rem 0 0 0" }}>
                  Total Sales
                </Typography.Title>
              </div>
              <Icon icon="carbon:sales-ops" fontSize={40} />
            </Flex>
          )}
        </Card>
      </Col>
    </>
  );
};