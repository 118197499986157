import React, { useState, useEffect } from "react";
import {
  Form,
  Input,
  Button,
  Col,
  Row,
  Checkbox,
  Typography,
  Card,
  Spin,
  Flex,
} from "antd";
import { useUsersServiceGetRole, useUsersServiceUpdateRole } from "api/queries";
import { useParams } from "react-router-dom";

const { Title } = Typography;

const EditRole = () => {
  const [roleName, setRoleName] = useState("");
  const [roleDescription, setRoleDescription] = useState("");
  const [checkedPermissions, setCheckedPermissions] = useState<any>({});
  const [indeterminateStates, setIndeterminateStates] = useState<any>({});

  const [form] = Form.useForm();
  const { id } = useParams();

  const { data, isLoading, isRefetching, refetch, isError } =
    useUsersServiceGetRole({
      roleId: +(id || 0),
    });

  const { mutate: updateRoleMutation, isPending } = useUsersServiceUpdateRole({
    onError: (error: any) => {
      console.dir(error);
    },
    onSuccess: async () => {
      await refetch();
    },
  });

  useEffect(() => {
    setCheckedPermissions(data?.rolePermissions || {});
    form.setFieldsValue({
      roleName: data?.roleName,
      roleDescription: data?.roleDescription,
    });
    setRoleDescription(data?.roleDescription || "");
    setRoleName(data?.roleName || "");
  }, [data]);

  useEffect(() => {
    const newIndeterminateStates: any = {};
    for (const permission in checkedPermissions) {
      const permissions = Object.values(checkedPermissions[permission]);
      const allChecked = permissions.every(Boolean);
      const noneChecked = permissions.every((value) => !value);
      newIndeterminateStates[permission] = !allChecked && !noneChecked;
    }
    setIndeterminateStates(newIndeterminateStates);
  }, [checkedPermissions]);

  const handleSubmit = (event: any) => {
    updateRoleMutation({
      roleId: +(id || 0),
      requestBody: {
        roleName: roleName,
        roleDescription: roleDescription,
        rolePermissions: checkedPermissions,
      },
    });
  };

  const handleCheckboxChange = (
    permission: string,
    action: string,
    checked: boolean
  ) => {
    setCheckedPermissions((prevPermissions: any) => ({
      ...prevPermissions,
      [permission]: {
        ...prevPermissions[permission],
        [action]: checked,
      },
    }));
  };

  const handleGroupCheckboxChange = (permission: string, checked: boolean) => {
    setCheckedPermissions((prevPermissions: any) => ({
      ...prevPermissions,
      [permission]: {
        CREATE: checked,
        READ: checked,
        UPDATE: checked,
        DELETE: checked,
      },
    }));
  };

  return (
    <Row gutter={16}>
      {(isLoading || isRefetching || isPending) && (
        <Col span={24}>
          <Flex justify="center" align="center" style={{ minHeight: 500 }}>
            <Spin />
          </Flex>
        </Col>
      )}
      {!isError && !isLoading && !isRefetching && !isPending && (
        <>
          <Col
            xs={24}
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography.Title level={2} style={{ margin: "0px" }}>
              Vendors
            </Typography.Title>

            <Button type="primary" onClick={form.submit}>
              Save
            </Button>
          </Col>

          <Col span={24}>
            <Form onFinish={handleSubmit} layout="vertical" form={form}>
              <Form.Item label="Role Name" name="roleName">
                <Input
                  value={roleName}
                  onChange={(e) => setRoleName(e.target.value)}
                  placeholder="Enter role name"
                  size="large"
                />
              </Form.Item>

              <Form.Item label="Role Description" name="roleDescription">
                <Input.TextArea
                  value={roleDescription}
                  onChange={(e) => setRoleDescription(e.target.value)}
                  placeholder="Enter role description"
                  rows={4}
                />
              </Form.Item>

              <Title level={3} style={{ marginBottom: "20px" }}>
                Permissions
              </Title>

              {Object?.keys(checkedPermissions)?.map((permission) => (
                <Card
                  key={permission}
                  title={
                    <Checkbox
                      indeterminate={indeterminateStates[permission]}
                      checked={Object.values(
                        checkedPermissions[permission]
                      ).every(Boolean)}
                      onChange={(e) =>
                        handleGroupCheckboxChange(permission, e.target.checked)
                      }
                    >
                      {permission.replace("_", " ")}
                    </Checkbox>
                  }
                  bordered={false}
                  style={{ marginBottom: "20px" }}
                >
                  <Row gutter={16}>
                    {["CREATE", "READ", "UPDATE", "DELETE"].map((action) => (
                      <Col span={6} key={action}>
                        <Checkbox
                          checked={checkedPermissions[permission][action]}
                          onChange={(e) =>
                            handleCheckboxChange(
                              permission,
                              action,
                              e.target.checked
                            )
                          }
                        >
                          {action.charAt(0).toUpperCase() + action.slice(1)}
                        </Checkbox>
                      </Col>
                    ))}
                  </Row>
                </Card>
              ))}
            </Form>
          </Col>
        </>
      )}
    </Row>
  );
};

export default EditRole;
