// generated with @7nohe/openapi-react-query-codegen@1.4.0 

import { UseQueryResult } from "@tanstack/react-query";
import { AccountingService, AuthService, CustomerService, InventoryReportsService, InventoryService, ReparationService, SalesService, SettingsService, UsersService, VendorService, VendorsService } from "../requests/services.gen";
export type UsersServiceGetUsersDefaultResponse = Awaited<ReturnType<typeof UsersService.getUsers>>;
export type UsersServiceGetUsersQueryResult<TData = UsersServiceGetUsersDefaultResponse, TError = unknown> = UseQueryResult<TData, TError>;
export const useUsersServiceGetUsersKey = "UsersServiceGetUsers";
export const UseUsersServiceGetUsersKeyFn = ({ filters, pagination, searchQuery, sort }: {
  filters: string;
  pagination: string;
  searchQuery?: string;
  sort: string;
}, queryKey?: Array<unknown>) => [useUsersServiceGetUsersKey, ...(queryKey ?? [{ filters, pagination, searchQuery, sort }])];
export type UsersServiceGetUserDefaultResponse = Awaited<ReturnType<typeof UsersService.getUser>>;
export type UsersServiceGetUserQueryResult<TData = UsersServiceGetUserDefaultResponse, TError = unknown> = UseQueryResult<TData, TError>;
export const useUsersServiceGetUserKey = "UsersServiceGetUser";
export const UseUsersServiceGetUserKeyFn = (queryKey?: Array<unknown>) => [useUsersServiceGetUserKey, ...(queryKey ?? [])];
export type UsersServiceGetRolesDefaultResponse = Awaited<ReturnType<typeof UsersService.getRoles>>;
export type UsersServiceGetRolesQueryResult<TData = UsersServiceGetRolesDefaultResponse, TError = unknown> = UseQueryResult<TData, TError>;
export const useUsersServiceGetRolesKey = "UsersServiceGetRoles";
export const UseUsersServiceGetRolesKeyFn = ({ filters, pagination, searchQuery, sort }: {
  filters: string;
  pagination: string;
  searchQuery?: string;
  sort: string;
}, queryKey?: Array<unknown>) => [useUsersServiceGetRolesKey, ...(queryKey ?? [{ filters, pagination, searchQuery, sort }])];
export type UsersServiceGetRoleDefaultResponse = Awaited<ReturnType<typeof UsersService.getRole>>;
export type UsersServiceGetRoleQueryResult<TData = UsersServiceGetRoleDefaultResponse, TError = unknown> = UseQueryResult<TData, TError>;
export const useUsersServiceGetRoleKey = "UsersServiceGetRole";
export const UseUsersServiceGetRoleKeyFn = ({ roleId }: {
  roleId: number;
}, queryKey?: Array<unknown>) => [useUsersServiceGetRoleKey, ...(queryKey ?? [{ roleId }])];
export type SettingsServiceGetProductVariantTypesDefaultResponse = Awaited<ReturnType<typeof SettingsService.getProductVariantTypes>>;
export type SettingsServiceGetProductVariantTypesQueryResult<TData = SettingsServiceGetProductVariantTypesDefaultResponse, TError = unknown> = UseQueryResult<TData, TError>;
export const useSettingsServiceGetProductVariantTypesKey = "SettingsServiceGetProductVariantTypes";
export const UseSettingsServiceGetProductVariantTypesKeyFn = ({ filters, pagination, searchQuery, sort }: {
  filters: string;
  pagination: string;
  searchQuery?: string;
  sort: string;
}, queryKey?: Array<unknown>) => [useSettingsServiceGetProductVariantTypesKey, ...(queryKey ?? [{ filters, pagination, searchQuery, sort }])];
export type SettingsServiceGetProductVariantTypeDefaultResponse = Awaited<ReturnType<typeof SettingsService.getProductVariantType>>;
export type SettingsServiceGetProductVariantTypeQueryResult<TData = SettingsServiceGetProductVariantTypeDefaultResponse, TError = unknown> = UseQueryResult<TData, TError>;
export const useSettingsServiceGetProductVariantTypeKey = "SettingsServiceGetProductVariantType";
export const UseSettingsServiceGetProductVariantTypeKeyFn = ({ id }: {
  id: number;
}, queryKey?: Array<unknown>) => [useSettingsServiceGetProductVariantTypeKey, ...(queryKey ?? [{ id }])];
export type SettingsServiceGetProductVariantValuesDefaultResponse = Awaited<ReturnType<typeof SettingsService.getProductVariantValues>>;
export type SettingsServiceGetProductVariantValuesQueryResult<TData = SettingsServiceGetProductVariantValuesDefaultResponse, TError = unknown> = UseQueryResult<TData, TError>;
export const useSettingsServiceGetProductVariantValuesKey = "SettingsServiceGetProductVariantValues";
export const UseSettingsServiceGetProductVariantValuesKeyFn = ({ filters, pagination, searchQuery, sort }: {
  filters: string;
  pagination: string;
  searchQuery?: string;
  sort: string;
}, queryKey?: Array<unknown>) => [useSettingsServiceGetProductVariantValuesKey, ...(queryKey ?? [{ filters, pagination, searchQuery, sort }])];
export type SettingsServiceGetProductVariantValueDefaultResponse = Awaited<ReturnType<typeof SettingsService.getProductVariantValue>>;
export type SettingsServiceGetProductVariantValueQueryResult<TData = SettingsServiceGetProductVariantValueDefaultResponse, TError = unknown> = UseQueryResult<TData, TError>;
export const useSettingsServiceGetProductVariantValueKey = "SettingsServiceGetProductVariantValue";
export const UseSettingsServiceGetProductVariantValueKeyFn = ({ id }: {
  id: number;
}, queryKey?: Array<unknown>) => [useSettingsServiceGetProductVariantValueKey, ...(queryKey ?? [{ id }])];
export type SettingsServiceGetCompanyDetailsDefaultResponse = Awaited<ReturnType<typeof SettingsService.getCompanyDetails>>;
export type SettingsServiceGetCompanyDetailsQueryResult<TData = SettingsServiceGetCompanyDetailsDefaultResponse, TError = unknown> = UseQueryResult<TData, TError>;
export const useSettingsServiceGetCompanyDetailsKey = "SettingsServiceGetCompanyDetails";
export const UseSettingsServiceGetCompanyDetailsKeyFn = (queryKey?: Array<unknown>) => [useSettingsServiceGetCompanyDetailsKey, ...(queryKey ?? [])];
export type VendorServiceGetAllVendorsDefaultResponse = Awaited<ReturnType<typeof VendorService.getAllVendors>>;
export type VendorServiceGetAllVendorsQueryResult<TData = VendorServiceGetAllVendorsDefaultResponse, TError = unknown> = UseQueryResult<TData, TError>;
export const useVendorServiceGetAllVendorsKey = "VendorServiceGetAllVendors";
export const UseVendorServiceGetAllVendorsKeyFn = ({ filters, pagination, searchQuery, sort }: {
  filters: string;
  pagination: string;
  searchQuery?: string;
  sort: string;
}, queryKey?: Array<unknown>) => [useVendorServiceGetAllVendorsKey, ...(queryKey ?? [{ filters, pagination, searchQuery, sort }])];
export type VendorsServiceGetVendorByIdDefaultResponse = Awaited<ReturnType<typeof VendorsService.getVendorById>>;
export type VendorsServiceGetVendorByIdQueryResult<TData = VendorsServiceGetVendorByIdDefaultResponse, TError = unknown> = UseQueryResult<TData, TError>;
export const useVendorsServiceGetVendorByIdKey = "VendorsServiceGetVendorById";
export const UseVendorsServiceGetVendorByIdKeyFn = ({ vendorId }: {
  vendorId: number;
}, queryKey?: Array<unknown>) => [useVendorsServiceGetVendorByIdKey, ...(queryKey ?? [{ vendorId }])];
export type VendorsServiceGetVendorPurchasesDefaultResponse = Awaited<ReturnType<typeof VendorsService.getVendorPurchases>>;
export type VendorsServiceGetVendorPurchasesQueryResult<TData = VendorsServiceGetVendorPurchasesDefaultResponse, TError = unknown> = UseQueryResult<TData, TError>;
export const useVendorsServiceGetVendorPurchasesKey = "VendorsServiceGetVendorPurchases";
export const UseVendorsServiceGetVendorPurchasesKeyFn = ({ filters, pagination, searchQuery, sort, vendorId }: {
  filters: string;
  pagination: string;
  searchQuery?: string;
  sort: string;
  vendorId: number;
}, queryKey?: Array<unknown>) => [useVendorsServiceGetVendorPurchasesKey, ...(queryKey ?? [{ filters, pagination, searchQuery, sort, vendorId }])];
export type VendorsServiceGetVendorStatementOfAccountDefaultResponse = Awaited<ReturnType<typeof VendorsService.getVendorStatementOfAccount>>;
export type VendorsServiceGetVendorStatementOfAccountQueryResult<TData = VendorsServiceGetVendorStatementOfAccountDefaultResponse, TError = unknown> = UseQueryResult<TData, TError>;
export const useVendorsServiceGetVendorStatementOfAccountKey = "VendorsServiceGetVendorStatementOfAccount";
export const UseVendorsServiceGetVendorStatementOfAccountKeyFn = ({ endDate, startDate, vendorId }: {
  endDate?: string;
  startDate?: string;
  vendorId: number;
}, queryKey?: Array<unknown>) => [useVendorsServiceGetVendorStatementOfAccountKey, ...(queryKey ?? [{ endDate, startDate, vendorId }])];
export type InventoryServiceGetServiceCategoryDefaultResponse = Awaited<ReturnType<typeof InventoryService.getServiceCategory>>;
export type InventoryServiceGetServiceCategoryQueryResult<TData = InventoryServiceGetServiceCategoryDefaultResponse, TError = unknown> = UseQueryResult<TData, TError>;
export const useInventoryServiceGetServiceCategoryKey = "InventoryServiceGetServiceCategory";
export const UseInventoryServiceGetServiceCategoryKeyFn = ({ filters, pagination, sort }: {
  filters: string;
  pagination: string;
  sort: string;
}, queryKey?: Array<unknown>) => [useInventoryServiceGetServiceCategoryKey, ...(queryKey ?? [{ filters, pagination, sort }])];
export type InventoryServiceGetServicesDefaultResponse = Awaited<ReturnType<typeof InventoryService.getServices>>;
export type InventoryServiceGetServicesQueryResult<TData = InventoryServiceGetServicesDefaultResponse, TError = unknown> = UseQueryResult<TData, TError>;
export const useInventoryServiceGetServicesKey = "InventoryServiceGetServices";
export const UseInventoryServiceGetServicesKeyFn = ({ filters, pagination, sort }: {
  filters: string;
  pagination: string;
  sort: string;
}, queryKey?: Array<unknown>) => [useInventoryServiceGetServicesKey, ...(queryKey ?? [{ filters, pagination, sort }])];
export type InventoryServiceGetLocationsDefaultResponse = Awaited<ReturnType<typeof InventoryService.getLocations>>;
export type InventoryServiceGetLocationsQueryResult<TData = InventoryServiceGetLocationsDefaultResponse, TError = unknown> = UseQueryResult<TData, TError>;
export const useInventoryServiceGetLocationsKey = "InventoryServiceGetLocations";
export const UseInventoryServiceGetLocationsKeyFn = ({ filters, pagination, searchQuery, sort }: {
  filters: string;
  pagination: string;
  searchQuery?: string;
  sort: string;
}, queryKey?: Array<unknown>) => [useInventoryServiceGetLocationsKey, ...(queryKey ?? [{ filters, pagination, searchQuery, sort }])];
export type InventoryServiceGetBrandsDefaultResponse = Awaited<ReturnType<typeof InventoryService.getBrands>>;
export type InventoryServiceGetBrandsQueryResult<TData = InventoryServiceGetBrandsDefaultResponse, TError = unknown> = UseQueryResult<TData, TError>;
export const useInventoryServiceGetBrandsKey = "InventoryServiceGetBrands";
export const UseInventoryServiceGetBrandsKeyFn = ({ filters, pagination, searchQuery, sort }: {
  filters: string;
  pagination: string;
  searchQuery?: string;
  sort: string;
}, queryKey?: Array<unknown>) => [useInventoryServiceGetBrandsKey, ...(queryKey ?? [{ filters, pagination, searchQuery, sort }])];
export type InventoryServiceGetProductCategoriesDefaultResponse = Awaited<ReturnType<typeof InventoryService.getProductCategories>>;
export type InventoryServiceGetProductCategoriesQueryResult<TData = InventoryServiceGetProductCategoriesDefaultResponse, TError = unknown> = UseQueryResult<TData, TError>;
export const useInventoryServiceGetProductCategoriesKey = "InventoryServiceGetProductCategories";
export const UseInventoryServiceGetProductCategoriesKeyFn = ({ filters, pagination, searchQuery, sort }: {
  filters: string;
  pagination: string;
  searchQuery?: string;
  sort: string;
}, queryKey?: Array<unknown>) => [useInventoryServiceGetProductCategoriesKey, ...(queryKey ?? [{ filters, pagination, searchQuery, sort }])];
export type InventoryServiceGetProductSubcategoryDefaultResponse = Awaited<ReturnType<typeof InventoryService.getProductSubcategory>>;
export type InventoryServiceGetProductSubcategoryQueryResult<TData = InventoryServiceGetProductSubcategoryDefaultResponse, TError = unknown> = UseQueryResult<TData, TError>;
export const useInventoryServiceGetProductSubcategoryKey = "InventoryServiceGetProductSubcategory";
export const UseInventoryServiceGetProductSubcategoryKeyFn = ({ filters, pagination, searchQuery, sort }: {
  filters: string;
  pagination: string;
  searchQuery?: string;
  sort: string;
}, queryKey?: Array<unknown>) => [useInventoryServiceGetProductSubcategoryKey, ...(queryKey ?? [{ filters, pagination, searchQuery, sort }])];
export type InventoryServiceGetProductParentCategoryDefaultResponse = Awaited<ReturnType<typeof InventoryService.getProductParentCategory>>;
export type InventoryServiceGetProductParentCategoryQueryResult<TData = InventoryServiceGetProductParentCategoryDefaultResponse, TError = unknown> = UseQueryResult<TData, TError>;
export const useInventoryServiceGetProductParentCategoryKey = "InventoryServiceGetProductParentCategory";
export const UseInventoryServiceGetProductParentCategoryKeyFn = ({ filters, pagination, searchQuery, sort }: {
  filters: string;
  pagination: string;
  searchQuery?: string;
  sort: string;
}, queryKey?: Array<unknown>) => [useInventoryServiceGetProductParentCategoryKey, ...(queryKey ?? [{ filters, pagination, searchQuery, sort }])];
export type InventoryServiceGetProductCategoryOptionItemsDefaultResponse = Awaited<ReturnType<typeof InventoryService.getProductCategoryOptionItems>>;
export type InventoryServiceGetProductCategoryOptionItemsQueryResult<TData = InventoryServiceGetProductCategoryOptionItemsDefaultResponse, TError = unknown> = UseQueryResult<TData, TError>;
export const useInventoryServiceGetProductCategoryOptionItemsKey = "InventoryServiceGetProductCategoryOptionItems";
export const UseInventoryServiceGetProductCategoryOptionItemsKeyFn = ({ filters, pagination, searchQuery, sort }: {
  filters: string;
  pagination: string;
  searchQuery?: string;
  sort: string;
}, queryKey?: Array<unknown>) => [useInventoryServiceGetProductCategoryOptionItemsKey, ...(queryKey ?? [{ filters, pagination, searchQuery, sort }])];
export type InventoryServiceGetProductsDefaultResponse = Awaited<ReturnType<typeof InventoryService.getProducts>>;
export type InventoryServiceGetProductsQueryResult<TData = InventoryServiceGetProductsDefaultResponse, TError = unknown> = UseQueryResult<TData, TError>;
export const useInventoryServiceGetProductsKey = "InventoryServiceGetProducts";
export const UseInventoryServiceGetProductsKeyFn = ({ filters, pagination, searchQuery, sort }: {
  filters: string;
  pagination: string;
  searchQuery?: string;
  sort: string;
}, queryKey?: Array<unknown>) => [useInventoryServiceGetProductsKey, ...(queryKey ?? [{ filters, pagination, searchQuery, sort }])];
export type InventoryServiceGetTotalProductsDefaultResponse = Awaited<ReturnType<typeof InventoryService.getTotalProducts>>;
export type InventoryServiceGetTotalProductsQueryResult<TData = InventoryServiceGetTotalProductsDefaultResponse, TError = unknown> = UseQueryResult<TData, TError>;
export const useInventoryServiceGetTotalProductsKey = "InventoryServiceGetTotalProducts";
export const UseInventoryServiceGetTotalProductsKeyFn = (queryKey?: Array<unknown>) => [useInventoryServiceGetTotalProductsKey, ...(queryKey ?? [])];
export type InventoryServiceGetAllProductsDefaultResponse = Awaited<ReturnType<typeof InventoryService.getAllProducts>>;
export type InventoryServiceGetAllProductsQueryResult<TData = InventoryServiceGetAllProductsDefaultResponse, TError = unknown> = UseQueryResult<TData, TError>;
export const useInventoryServiceGetAllProductsKey = "InventoryServiceGetAllProducts";
export const UseInventoryServiceGetAllProductsKeyFn = ({ filters, pagination, searchQuery, sort }: {
  filters: string;
  pagination: string;
  searchQuery?: string;
  sort: string;
}, queryKey?: Array<unknown>) => [useInventoryServiceGetAllProductsKey, ...(queryKey ?? [{ filters, pagination, searchQuery, sort }])];
export type InventoryServiceGetShopProductsDefaultResponse = Awaited<ReturnType<typeof InventoryService.getShopProducts>>;
export type InventoryServiceGetShopProductsQueryResult<TData = InventoryServiceGetShopProductsDefaultResponse, TError = unknown> = UseQueryResult<TData, TError>;
export const useInventoryServiceGetShopProductsKey = "InventoryServiceGetShopProducts";
export const UseInventoryServiceGetShopProductsKeyFn = ({ limit, search }: {
  limit: number;
  search?: string;
}, queryKey?: Array<unknown>) => [useInventoryServiceGetShopProductsKey, ...(queryKey ?? [{ limit, search }])];
export type InventoryServiceGetProductDefaultResponse = Awaited<ReturnType<typeof InventoryService.getProduct>>;
export type InventoryServiceGetProductQueryResult<TData = InventoryServiceGetProductDefaultResponse, TError = unknown> = UseQueryResult<TData, TError>;
export const useInventoryServiceGetProductKey = "InventoryServiceGetProduct";
export const UseInventoryServiceGetProductKeyFn = ({ id }: {
  id: number;
}, queryKey?: Array<unknown>) => [useInventoryServiceGetProductKey, ...(queryKey ?? [{ id }])];
export type InventoryServiceGetProductVariantTypesDefaultResponse = Awaited<ReturnType<typeof InventoryService.getProductVariantTypes>>;
export type InventoryServiceGetProductVariantTypesQueryResult<TData = InventoryServiceGetProductVariantTypesDefaultResponse, TError = unknown> = UseQueryResult<TData, TError>;
export const useInventoryServiceGetProductVariantTypesKey = "InventoryServiceGetProductVariantTypes";
export const UseInventoryServiceGetProductVariantTypesKeyFn = ({ filters, pagination, searchQuery, sort }: {
  filters: string;
  pagination: string;
  searchQuery?: string;
  sort: string;
}, queryKey?: Array<unknown>) => [useInventoryServiceGetProductVariantTypesKey, ...(queryKey ?? [{ filters, pagination, searchQuery, sort }])];
export type InventoryServiceGetPurchaseOrdersDefaultResponse = Awaited<ReturnType<typeof InventoryService.getPurchaseOrders>>;
export type InventoryServiceGetPurchaseOrdersQueryResult<TData = InventoryServiceGetPurchaseOrdersDefaultResponse, TError = unknown> = UseQueryResult<TData, TError>;
export const useInventoryServiceGetPurchaseOrdersKey = "InventoryServiceGetPurchaseOrders";
export const UseInventoryServiceGetPurchaseOrdersKeyFn = ({ filters, pagination, searchQuery, sort }: {
  filters: string;
  pagination: string;
  searchQuery?: string;
  sort: string;
}, queryKey?: Array<unknown>) => [useInventoryServiceGetPurchaseOrdersKey, ...(queryKey ?? [{ filters, pagination, searchQuery, sort }])];
export type InventoryServiceGetPurchaseOrderDefaultResponse = Awaited<ReturnType<typeof InventoryService.getPurchaseOrder>>;
export type InventoryServiceGetPurchaseOrderQueryResult<TData = InventoryServiceGetPurchaseOrderDefaultResponse, TError = unknown> = UseQueryResult<TData, TError>;
export const useInventoryServiceGetPurchaseOrderKey = "InventoryServiceGetPurchaseOrder";
export const UseInventoryServiceGetPurchaseOrderKeyFn = ({ id }: {
  id: number;
}, queryKey?: Array<unknown>) => [useInventoryServiceGetPurchaseOrderKey, ...(queryKey ?? [{ id }])];
export type InventoryServiceGetPurchaseOrderPaymentsDefaultResponse = Awaited<ReturnType<typeof InventoryService.getPurchaseOrderPayments>>;
export type InventoryServiceGetPurchaseOrderPaymentsQueryResult<TData = InventoryServiceGetPurchaseOrderPaymentsDefaultResponse, TError = unknown> = UseQueryResult<TData, TError>;
export const useInventoryServiceGetPurchaseOrderPaymentsKey = "InventoryServiceGetPurchaseOrderPayments";
export const UseInventoryServiceGetPurchaseOrderPaymentsKeyFn = ({ filters, pagination, purchaseOrderId, searchQuery, sort }: {
  filters: string;
  pagination: string;
  purchaseOrderId: number;
  searchQuery?: string;
  sort: string;
}, queryKey?: Array<unknown>) => [useInventoryServiceGetPurchaseOrderPaymentsKey, ...(queryKey ?? [{ filters, pagination, purchaseOrderId, searchQuery, sort }])];
export type InventoryServiceGetInventoryTransactionsDefaultResponse = Awaited<ReturnType<typeof InventoryService.getInventoryTransactions>>;
export type InventoryServiceGetInventoryTransactionsQueryResult<TData = InventoryServiceGetInventoryTransactionsDefaultResponse, TError = unknown> = UseQueryResult<TData, TError>;
export const useInventoryServiceGetInventoryTransactionsKey = "InventoryServiceGetInventoryTransactions";
export const UseInventoryServiceGetInventoryTransactionsKeyFn = ({ filters, pagination, searchQuery, sort }: {
  filters: string;
  pagination: string;
  searchQuery?: string;
  sort: string;
}, queryKey?: Array<unknown>) => [useInventoryServiceGetInventoryTransactionsKey, ...(queryKey ?? [{ filters, pagination, searchQuery, sort }])];
export type InventoryServiceGetModelsDefaultResponse = Awaited<ReturnType<typeof InventoryService.getModels>>;
export type InventoryServiceGetModelsQueryResult<TData = InventoryServiceGetModelsDefaultResponse, TError = unknown> = UseQueryResult<TData, TError>;
export const useInventoryServiceGetModelsKey = "InventoryServiceGetModels";
export const UseInventoryServiceGetModelsKeyFn = ({ filters, pagination, searchQuery, sort }: {
  filters: string;
  pagination: string;
  searchQuery?: string;
  sort: string;
}, queryKey?: Array<unknown>) => [useInventoryServiceGetModelsKey, ...(queryKey ?? [{ filters, pagination, searchQuery, sort }])];
export type InventoryServiceGetModelCategoriesDefaultResponse = Awaited<ReturnType<typeof InventoryService.getModelCategories>>;
export type InventoryServiceGetModelCategoriesQueryResult<TData = InventoryServiceGetModelCategoriesDefaultResponse, TError = unknown> = UseQueryResult<TData, TError>;
export const useInventoryServiceGetModelCategoriesKey = "InventoryServiceGetModelCategories";
export const UseInventoryServiceGetModelCategoriesKeyFn = ({ filters, pagination, searchQuery, sort }: {
  filters: string;
  pagination: string;
  searchQuery?: string;
  sort: string;
}, queryKey?: Array<unknown>) => [useInventoryServiceGetModelCategoriesKey, ...(queryKey ?? [{ filters, pagination, searchQuery, sort }])];
export type InventoryServiceGetInventoryStockDefaultResponse = Awaited<ReturnType<typeof InventoryService.getInventoryStock>>;
export type InventoryServiceGetInventoryStockQueryResult<TData = InventoryServiceGetInventoryStockDefaultResponse, TError = unknown> = UseQueryResult<TData, TError>;
export const useInventoryServiceGetInventoryStockKey = "InventoryServiceGetInventoryStock";
export const UseInventoryServiceGetInventoryStockKeyFn = ({ filters, pagination, searchQuery, sort }: {
  filters: string;
  pagination: string;
  searchQuery?: string;
  sort: string;
}, queryKey?: Array<unknown>) => [useInventoryServiceGetInventoryStockKey, ...(queryKey ?? [{ filters, pagination, searchQuery, sort }])];
export type SalesServiceGetInvoicesDefaultResponse = Awaited<ReturnType<typeof SalesService.getInvoices>>;
export type SalesServiceGetInvoicesQueryResult<TData = SalesServiceGetInvoicesDefaultResponse, TError = unknown> = UseQueryResult<TData, TError>;
export const useSalesServiceGetInvoicesKey = "SalesServiceGetInvoices";
export const UseSalesServiceGetInvoicesKeyFn = ({ filters, pagination, searchQuery, sort }: {
  filters: string;
  pagination: string;
  searchQuery?: string;
  sort: string;
}, queryKey?: Array<unknown>) => [useSalesServiceGetInvoicesKey, ...(queryKey ?? [{ filters, pagination, searchQuery, sort }])];
export type SalesServiceGetInvoiceDetailsDefaultResponse = Awaited<ReturnType<typeof SalesService.getInvoiceDetails>>;
export type SalesServiceGetInvoiceDetailsQueryResult<TData = SalesServiceGetInvoiceDetailsDefaultResponse, TError = unknown> = UseQueryResult<TData, TError>;
export const useSalesServiceGetInvoiceDetailsKey = "SalesServiceGetInvoiceDetails";
export const UseSalesServiceGetInvoiceDetailsKeyFn = ({ idInvoice }: {
  idInvoice: number;
}, queryKey?: Array<unknown>) => [useSalesServiceGetInvoiceDetailsKey, ...(queryKey ?? [{ idInvoice }])];
export type InventoryReportsServiceGetInventoryStockReportDefaultResponse = Awaited<ReturnType<typeof InventoryReportsService.getInventoryStockReport>>;
export type InventoryReportsServiceGetInventoryStockReportQueryResult<TData = InventoryReportsServiceGetInventoryStockReportDefaultResponse, TError = unknown> = UseQueryResult<TData, TError>;
export const useInventoryReportsServiceGetInventoryStockReportKey = "InventoryReportsServiceGetInventoryStockReport";
export const UseInventoryReportsServiceGetInventoryStockReportKeyFn = ({ filters, pagination, sort }: {
  filters?: string;
  pagination?: string;
  sort?: string;
} = {}, queryKey?: Array<unknown>) => [useInventoryReportsServiceGetInventoryStockReportKey, ...(queryKey ?? [{ filters, pagination, sort }])];
export type InventoryReportsServiceGetDashboardOverviewDefaultResponse = Awaited<ReturnType<typeof InventoryReportsService.getDashboardOverview>>;
export type InventoryReportsServiceGetDashboardOverviewQueryResult<TData = InventoryReportsServiceGetDashboardOverviewDefaultResponse, TError = unknown> = UseQueryResult<TData, TError>;
export const useInventoryReportsServiceGetDashboardOverviewKey = "InventoryReportsServiceGetDashboardOverview";
export const UseInventoryReportsServiceGetDashboardOverviewKeyFn = ({ range }: {
  range: "TODAY" | "THIS_WEEK" | "THIS_MONTH" | "THIS_YEAR";
}, queryKey?: Array<unknown>) => [useInventoryReportsServiceGetDashboardOverviewKey, ...(queryKey ?? [{ range }])];
export type ReparationServiceGetChecklistItemsDefaultResponse = Awaited<ReturnType<typeof ReparationService.getChecklistItems>>;
export type ReparationServiceGetChecklistItemsQueryResult<TData = ReparationServiceGetChecklistItemsDefaultResponse, TError = unknown> = UseQueryResult<TData, TError>;
export const useReparationServiceGetChecklistItemsKey = "ReparationServiceGetChecklistItems";
export const UseReparationServiceGetChecklistItemsKeyFn = (queryKey?: Array<unknown>) => [useReparationServiceGetChecklistItemsKey, ...(queryKey ?? [])];
export type ReparationServiceGetReparationCasesDefaultResponse = Awaited<ReturnType<typeof ReparationService.getReparationCases>>;
export type ReparationServiceGetReparationCasesQueryResult<TData = ReparationServiceGetReparationCasesDefaultResponse, TError = unknown> = UseQueryResult<TData, TError>;
export const useReparationServiceGetReparationCasesKey = "ReparationServiceGetReparationCases";
export const UseReparationServiceGetReparationCasesKeyFn = ({ limit, offset, search, sort, status }: {
  limit: number;
  offset: number;
  search: string;
  sort: string;
  status: string;
}, queryKey?: Array<unknown>) => [useReparationServiceGetReparationCasesKey, ...(queryKey ?? [{ limit, offset, search, sort, status }])];
export type ReparationServiceGetReparationCaseDefaultResponse = Awaited<ReturnType<typeof ReparationService.getReparationCase>>;
export type ReparationServiceGetReparationCaseQueryResult<TData = ReparationServiceGetReparationCaseDefaultResponse, TError = unknown> = UseQueryResult<TData, TError>;
export const useReparationServiceGetReparationCaseKey = "ReparationServiceGetReparationCase";
export const UseReparationServiceGetReparationCaseKeyFn = ({ id }: {
  id: number;
}, queryKey?: Array<unknown>) => [useReparationServiceGetReparationCaseKey, ...(queryKey ?? [{ id }])];
export type CustomerServiceGetCustomersDefaultResponse = Awaited<ReturnType<typeof CustomerService.getCustomers>>;
export type CustomerServiceGetCustomersQueryResult<TData = CustomerServiceGetCustomersDefaultResponse, TError = unknown> = UseQueryResult<TData, TError>;
export const useCustomerServiceGetCustomersKey = "CustomerServiceGetCustomers";
export const UseCustomerServiceGetCustomersKeyFn = ({ filters, pagination, searchQuery, sort }: {
  filters: string;
  pagination: string;
  searchQuery?: string;
  sort: string;
}, queryKey?: Array<unknown>) => [useCustomerServiceGetCustomersKey, ...(queryKey ?? [{ filters, pagination, searchQuery, sort }])];
export type CustomerServiceGetCustomerDefaultResponse = Awaited<ReturnType<typeof CustomerService.getCustomer>>;
export type CustomerServiceGetCustomerQueryResult<TData = CustomerServiceGetCustomerDefaultResponse, TError = unknown> = UseQueryResult<TData, TError>;
export const useCustomerServiceGetCustomerKey = "CustomerServiceGetCustomer";
export const UseCustomerServiceGetCustomerKeyFn = ({ idCustomer }: {
  idCustomer: string;
}, queryKey?: Array<unknown>) => [useCustomerServiceGetCustomerKey, ...(queryKey ?? [{ idCustomer }])];
export type AccountingServiceGetAccountsDefaultResponse = Awaited<ReturnType<typeof AccountingService.getAccounts>>;
export type AccountingServiceGetAccountsQueryResult<TData = AccountingServiceGetAccountsDefaultResponse, TError = unknown> = UseQueryResult<TData, TError>;
export const useAccountingServiceGetAccountsKey = "AccountingServiceGetAccounts";
export const UseAccountingServiceGetAccountsKeyFn = ({ filters, pagination, sort }: {
  filters: string;
  pagination: string;
  sort: string;
}, queryKey?: Array<unknown>) => [useAccountingServiceGetAccountsKey, ...(queryKey ?? [{ filters, pagination, sort }])];
export type AccountingServiceGetPaymentAccountsDefaultResponse = Awaited<ReturnType<typeof AccountingService.getPaymentAccounts>>;
export type AccountingServiceGetPaymentAccountsQueryResult<TData = AccountingServiceGetPaymentAccountsDefaultResponse, TError = unknown> = UseQueryResult<TData, TError>;
export const useAccountingServiceGetPaymentAccountsKey = "AccountingServiceGetPaymentAccounts";
export const UseAccountingServiceGetPaymentAccountsKeyFn = ({ filters, pagination, sort }: {
  filters: string;
  pagination: string;
  sort: string;
}, queryKey?: Array<unknown>) => [useAccountingServiceGetPaymentAccountsKey, ...(queryKey ?? [{ filters, pagination, sort }])];
export type AccountingServiceGetExpenseAccountsDefaultResponse = Awaited<ReturnType<typeof AccountingService.getExpenseAccounts>>;
export type AccountingServiceGetExpenseAccountsQueryResult<TData = AccountingServiceGetExpenseAccountsDefaultResponse, TError = unknown> = UseQueryResult<TData, TError>;
export const useAccountingServiceGetExpenseAccountsKey = "AccountingServiceGetExpenseAccounts";
export const UseAccountingServiceGetExpenseAccountsKeyFn = ({ filters, pagination, sort }: {
  filters: string;
  pagination: string;
  sort: string;
}, queryKey?: Array<unknown>) => [useAccountingServiceGetExpenseAccountsKey, ...(queryKey ?? [{ filters, pagination, sort }])];
export type AccountingServiceGetTransactionsDefaultResponse = Awaited<ReturnType<typeof AccountingService.getTransactions>>;
export type AccountingServiceGetTransactionsQueryResult<TData = AccountingServiceGetTransactionsDefaultResponse, TError = unknown> = UseQueryResult<TData, TError>;
export const useAccountingServiceGetTransactionsKey = "AccountingServiceGetTransactions";
export const UseAccountingServiceGetTransactionsKeyFn = ({ filters, pagination, sort }: {
  filters: string;
  pagination: string;
  sort: string;
}, queryKey?: Array<unknown>) => [useAccountingServiceGetTransactionsKey, ...(queryKey ?? [{ filters, pagination, sort }])];
export type AccountingServiceGetExpenseCategoriesDefaultResponse = Awaited<ReturnType<typeof AccountingService.getExpenseCategories>>;
export type AccountingServiceGetExpenseCategoriesQueryResult<TData = AccountingServiceGetExpenseCategoriesDefaultResponse, TError = unknown> = UseQueryResult<TData, TError>;
export const useAccountingServiceGetExpenseCategoriesKey = "AccountingServiceGetExpenseCategories";
export const UseAccountingServiceGetExpenseCategoriesKeyFn = ({ filters, pagination, sort }: {
  filters: string;
  pagination: string;
  sort: string;
}, queryKey?: Array<unknown>) => [useAccountingServiceGetExpenseCategoriesKey, ...(queryKey ?? [{ filters, pagination, sort }])];
export type AccountingServiceGetExpensesDefaultResponse = Awaited<ReturnType<typeof AccountingService.getExpenses>>;
export type AccountingServiceGetExpensesQueryResult<TData = AccountingServiceGetExpensesDefaultResponse, TError = unknown> = UseQueryResult<TData, TError>;
export const useAccountingServiceGetExpensesKey = "AccountingServiceGetExpenses";
export const UseAccountingServiceGetExpensesKeyFn = ({ filters, pagination, sort }: {
  filters: string;
  pagination: string;
  sort: string;
}, queryKey?: Array<unknown>) => [useAccountingServiceGetExpensesKey, ...(queryKey ?? [{ filters, pagination, sort }])];
export type AccountingServiceGetTaxesDefaultResponse = Awaited<ReturnType<typeof AccountingService.getTaxes>>;
export type AccountingServiceGetTaxesQueryResult<TData = AccountingServiceGetTaxesDefaultResponse, TError = unknown> = UseQueryResult<TData, TError>;
export const useAccountingServiceGetTaxesKey = "AccountingServiceGetTaxes";
export const UseAccountingServiceGetTaxesKeyFn = ({ filters, pagination, sort }: {
  filters: string;
  pagination: string;
  sort: string;
}, queryKey?: Array<unknown>) => [useAccountingServiceGetTaxesKey, ...(queryKey ?? [{ filters, pagination, sort }])];
export type AccountingServiceGetCurrenciesDefaultResponse = Awaited<ReturnType<typeof AccountingService.getCurrencies>>;
export type AccountingServiceGetCurrenciesQueryResult<TData = AccountingServiceGetCurrenciesDefaultResponse, TError = unknown> = UseQueryResult<TData, TError>;
export const useAccountingServiceGetCurrenciesKey = "AccountingServiceGetCurrencies";
export const UseAccountingServiceGetCurrenciesKeyFn = ({ filters, pagination, sort }: {
  filters: string;
  pagination: string;
  sort: string;
}, queryKey?: Array<unknown>) => [useAccountingServiceGetCurrenciesKey, ...(queryKey ?? [{ filters, pagination, sort }])];
export type AccountingServiceGetBankAccountsDefaultResponse = Awaited<ReturnType<typeof AccountingService.getBankAccounts>>;
export type AccountingServiceGetBankAccountsQueryResult<TData = AccountingServiceGetBankAccountsDefaultResponse, TError = unknown> = UseQueryResult<TData, TError>;
export const useAccountingServiceGetBankAccountsKey = "AccountingServiceGetBankAccounts";
export const UseAccountingServiceGetBankAccountsKeyFn = ({ filters, pagination, sort }: {
  filters: string;
  pagination: string;
  sort: string;
}, queryKey?: Array<unknown>) => [useAccountingServiceGetBankAccountsKey, ...(queryKey ?? [{ filters, pagination, sort }])];
export type AccountingServiceGetBankAccountDefaultResponse = Awaited<ReturnType<typeof AccountingService.getBankAccount>>;
export type AccountingServiceGetBankAccountQueryResult<TData = AccountingServiceGetBankAccountDefaultResponse, TError = unknown> = UseQueryResult<TData, TError>;
export const useAccountingServiceGetBankAccountKey = "AccountingServiceGetBankAccount";
export const UseAccountingServiceGetBankAccountKeyFn = ({ id }: {
  id: string;
}, queryKey?: Array<unknown>) => [useAccountingServiceGetBankAccountKey, ...(queryKey ?? [{ id }])];
export type AuthServiceLoginMutationResult = Awaited<ReturnType<typeof AuthService.login>>;
export type UsersServiceCreateUserMutationResult = Awaited<ReturnType<typeof UsersService.createUser>>;
export type UsersServiceCreateRoleMutationResult = Awaited<ReturnType<typeof UsersService.createRole>>;
export type SettingsServiceCreateProductVariantTypeMutationResult = Awaited<ReturnType<typeof SettingsService.createProductVariantType>>;
export type SettingsServiceCreateProductVariantValueMutationResult = Awaited<ReturnType<typeof SettingsService.createProductVariantValue>>;
export type SettingsServiceBackupDatabaseControllerMutationResult = Awaited<ReturnType<typeof SettingsService.backupDatabaseController>>;
export type SettingsServiceRestoreDatabaseMutationResult = Awaited<ReturnType<typeof SettingsService.restoreDatabase>>;
export type VendorServiceCreateVendorMutationResult = Awaited<ReturnType<typeof VendorService.createVendor>>;
export type InventoryServiceCreateServiceCategoryMutationResult = Awaited<ReturnType<typeof InventoryService.createServiceCategory>>;
export type InventoryServiceCreateServiceMutationResult = Awaited<ReturnType<typeof InventoryService.createService>>;
export type InventoryServiceCreateLocationMutationResult = Awaited<ReturnType<typeof InventoryService.createLocation>>;
export type InventoryServiceCreateBrandMutationResult = Awaited<ReturnType<typeof InventoryService.createBrand>>;
export type InventoryServiceCreateProductCategoryMutationResult = Awaited<ReturnType<typeof InventoryService.createProductCategory>>;
export type InventoryServiceCreateProductMutationResult = Awaited<ReturnType<typeof InventoryService.createProduct>>;
export type InventoryServiceDownloadProductListMutationResult = Awaited<ReturnType<typeof InventoryService.downloadProductList>>;
export type InventoryServiceGetLocationProductQuantityMutationResult = Awaited<ReturnType<typeof InventoryService.getLocationProductQuantity>>;
export type InventoryServiceCreateProductVariantMutationResult = Awaited<ReturnType<typeof InventoryService.createProductVariant>>;
export type InventoryServiceCreatePurchaseOrderMutationResult = Awaited<ReturnType<typeof InventoryService.createPurchaseOrder>>;
export type InventoryServiceApprovePurchaseOrderMutationResult = Awaited<ReturnType<typeof InventoryService.approvePurchaseOrder>>;
export type InventoryServiceRecordPurchaseOrderPaymentMutationResult = Awaited<ReturnType<typeof InventoryService.recordPurchaseOrderPayment>>;
export type InventoryServiceUpdatePurchaseDeliveryStatusMutationResult = Awaited<ReturnType<typeof InventoryService.updatePurchaseDeliveryStatus>>;
export type InventoryServiceCreateDeliveryNoteMutationResult = Awaited<ReturnType<typeof InventoryService.createDeliveryNote>>;
export type InventoryServiceCreateInventoryTransactionMutationResult = Awaited<ReturnType<typeof InventoryService.createInventoryTransaction>>;
export type InventoryServiceCreateModelMutationResult = Awaited<ReturnType<typeof InventoryService.createModel>>;
export type InventoryServiceCreateModelCategoryMutationResult = Awaited<ReturnType<typeof InventoryService.createModelCategory>>;
export type InventoryServiceDownloadInventoryStockReportMutationResult = Awaited<ReturnType<typeof InventoryService.downloadInventoryStockReport>>;
export type SalesServiceCreateInvoiceMutationResult = Awaited<ReturnType<typeof SalesService.createInvoice>>;
export type SalesServiceCreateInvoicePaymentMutationResult = Awaited<ReturnType<typeof SalesService.createInvoicePayment>>;
export type SalesServiceCancelInvoiceMutationResult = Awaited<ReturnType<typeof SalesService.cancelInvoice>>;
export type ReparationServiceCreateReparationCaseMutationResult = Awaited<ReturnType<typeof ReparationService.createReparationCase>>;
export type ReparationServiceCreateOrUpdateReviewMutationResult = Awaited<ReturnType<typeof ReparationService.createOrUpdateReview>>;
export type ReparationServiceCreateReparationCaseItemMutationResult = Awaited<ReturnType<typeof ReparationService.createReparationCaseItem>>;
export type CustomerServiceCreateCustomerMutationResult = Awaited<ReturnType<typeof CustomerService.createCustomer>>;
export type AccountingServiceCreateTransactionMutationResult = Awaited<ReturnType<typeof AccountingService.createTransaction>>;
export type AccountingServiceCreateExpenseCategoryMutationResult = Awaited<ReturnType<typeof AccountingService.createExpenseCategory>>;
export type AccountingServiceCreateExpenseMutationResult = Awaited<ReturnType<typeof AccountingService.createExpense>>;
export type AccountingServiceCreateTaxMutationResult = Awaited<ReturnType<typeof AccountingService.createTax>>;
export type AccountingServiceCreateCurrencyMutationResult = Awaited<ReturnType<typeof AccountingService.createCurrency>>;
export type AccountingServiceCreateBankAccountMutationResult = Awaited<ReturnType<typeof AccountingService.createBankAccount>>;
export type AccountingServiceCreateAccountMutationResult = Awaited<ReturnType<typeof AccountingService.createAccount>>;
export type UsersServiceUpdateUserMutationResult = Awaited<ReturnType<typeof UsersService.updateUser>>;
export type UsersServiceUpdateRoleMutationResult = Awaited<ReturnType<typeof UsersService.updateRole>>;
export type SettingsServiceUpdateProductVariantTypeMutationResult = Awaited<ReturnType<typeof SettingsService.updateProductVariantType>>;
export type SettingsServiceUpdateProductVariantValueMutationResult = Awaited<ReturnType<typeof SettingsService.updateProductVariantValue>>;
export type SettingsServiceUpdateCompanyDetailsMutationResult = Awaited<ReturnType<typeof SettingsService.updateCompanyDetails>>;
export type VendorsServiceUpdateVendorMutationResult = Awaited<ReturnType<typeof VendorsService.updateVendor>>;
export type InventoryServiceUpdateLocationMutationResult = Awaited<ReturnType<typeof InventoryService.updateLocation>>;
export type InventoryServiceUpdateBrandMutationResult = Awaited<ReturnType<typeof InventoryService.updateBrand>>;
export type InventoryServiceUpdateProductCategoryMutationResult = Awaited<ReturnType<typeof InventoryService.updateProductCategory>>;
export type InventoryServiceUpdateProductMutationResult = Awaited<ReturnType<typeof InventoryService.updateProduct>>;
export type InventoryServiceUpdateProductVariantMutationResult = Awaited<ReturnType<typeof InventoryService.updateProductVariant>>;
export type InventoryServiceUpdateModelMutationResult = Awaited<ReturnType<typeof InventoryService.updateModel>>;
export type InventoryServiceUpdateModelCategoryMutationResult = Awaited<ReturnType<typeof InventoryService.updateModelCategory>>;
export type ReparationServiceUpdateReparationCaseItemMutationResult = Awaited<ReturnType<typeof ReparationService.updateReparationCaseItem>>;
export type CustomerServiceUpdateCustomerMutationResult = Awaited<ReturnType<typeof CustomerService.updateCustomer>>;
export type AccountingServiceUpdateTransactionMutationResult = Awaited<ReturnType<typeof AccountingService.updateTransaction>>;
export type AccountingServiceUpdateExpenseMutationResult = Awaited<ReturnType<typeof AccountingService.updateExpense>>;
export type AccountingServiceUpdateExpenseCategoryMutationResult = Awaited<ReturnType<typeof AccountingService.updateExpenseCategory>>;
export type AccountingServiceUpdateTaxMutationResult = Awaited<ReturnType<typeof AccountingService.updateTax>>;
export type AccountingServiceUpdateCurrencyMutationResult = Awaited<ReturnType<typeof AccountingService.updateCurrency>>;
export type AccountingServiceUpdateBankAccountMutationResult = Awaited<ReturnType<typeof AccountingService.updateBankAccount>>;
export type UsersServiceDeleteUserMutationResult = Awaited<ReturnType<typeof UsersService.deleteUser>>;
export type UsersServiceDeleteRoleMutationResult = Awaited<ReturnType<typeof UsersService.deleteRole>>;
export type SettingsServiceDeleteProductVariantTypeMutationResult = Awaited<ReturnType<typeof SettingsService.deleteProductVariantType>>;
export type SettingsServiceDeleteProductVariantValueMutationResult = Awaited<ReturnType<typeof SettingsService.deleteProductVariantValue>>;
export type VendorsServiceDeleteVendorMutationResult = Awaited<ReturnType<typeof VendorsService.deleteVendor>>;
export type InventoryServiceDeleteLocationMutationResult = Awaited<ReturnType<typeof InventoryService.deleteLocation>>;
export type InventoryServiceDeleteBrandMutationResult = Awaited<ReturnType<typeof InventoryService.deleteBrand>>;
export type InventoryServiceDeleteProductCategoryMutationResult = Awaited<ReturnType<typeof InventoryService.deleteProductCategory>>;
export type InventoryServiceDeleteProductMutationResult = Awaited<ReturnType<typeof InventoryService.deleteProduct>>;
export type InventoryServiceDeleteProductVariantMutationResult = Awaited<ReturnType<typeof InventoryService.deleteProductVariant>>;
export type InventoryServiceDeletePurchaseOrderMutationResult = Awaited<ReturnType<typeof InventoryService.deletePurchaseOrder>>;
export type InventoryServiceDeleteModelMutationResult = Awaited<ReturnType<typeof InventoryService.deleteModel>>;
export type ReparationServiceDeleteReparationCaseItemMutationResult = Awaited<ReturnType<typeof ReparationService.deleteReparationCaseItem>>;
export type CustomerServiceDeleteCustomerMutationResult = Awaited<ReturnType<typeof CustomerService.deleteCustomer>>;
export type AccountingServiceDeleteTransactionMutationResult = Awaited<ReturnType<typeof AccountingService.deleteTransaction>>;
export type AccountingServiceDeleteExpenseMutationResult = Awaited<ReturnType<typeof AccountingService.deleteExpense>>;
export type AccountingServiceDeleteExpenseCategoryMutationResult = Awaited<ReturnType<typeof AccountingService.deleteExpenseCategory>>;
export type AccountingServiceDeleteTaxMutationResult = Awaited<ReturnType<typeof AccountingService.deleteTax>>;
export type AccountingServiceDeleteBankAccountMutationResult = Awaited<ReturnType<typeof AccountingService.deleteBankAccount>>;
